import React from "react"

import PropTypes from "prop-types"

import Section from "../section"
import Container from "../container"
import Box from "../box"

import Image from "../image"
import clsx from "clsx";
import Responsive from "../../utils/responsive"

const HeaderSection = (props) => (
  <Section className={clsx("header-section",
    props.type && `type-${props.type}`
  )}>
    <Container alignItems={"center"} appear={"fromTop"}>
      { props.type && props.type === "red-background" &&
        <div className={"background"} />
      }
      <Box className={"title-box"}>
        <h1>{ props.shortTitle ? Responsive.getCurrentBreakpoint() === 0 ? props.shortTitle : props.title : props.title }</h1>
      </Box>
      <Box className={"image-box"}>
        <Image
          src={ typeof props.image === "string" ? props.image : undefined }
          fluid={ props.image.fluid }
          fixed={ props.image.fixed }
          alt={ props.title }
        />
      </Box>
    </Container>
  </Section>
)

HeaderSection.propTypes = {
  title: PropTypes.string,
  shortTitle: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  type: PropTypes.oneOf([
    "red-background",
    "image-fullscreen",
  ])
}

HeaderSection.defaultProps = {
  type: "red-background",
}

export default HeaderSection