import React from "react"

import clsx from "clsx"
import PropTypes from "prop-types"
import { Link as GatsbyLink } from "gatsby"

import Responsive from "../../../utils/responsive"
import Icon from "../../icon";

const ButtonLink = (props) => {

  const Link = props.href
    ? (props) => <a {...props}>{props.children}</a>
    : GatsbyLink

  return (
    <Link {...props} to={props.to} href={props.href} className={clsx("button", "button-link",
      props.invisibleFrom && `invisible-from-${props.invisibleFrom}`,
      props.invisibleTo && `invisible-to-${props.invisibleTo}`,
    )}>
      {props.children}
    </Link>
  )
}

ButtonLink.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  invisibleFrom: PropTypes.oneOf(
    Object.keys(Responsive.breakpoints)
  ),
  invisibleTo: PropTypes.oneOf(
    Object.keys(Responsive.breakpoints)
  ),
}

ButtonLink.defaultProps = {
  to: "/",
}


export const MailButtonLink = (props) => (
  <ButtonLink {...props} to={undefined} href={`mailto:${props.mailto}`}>
    <Icon type={"mail"} />
    {props.children}
  </ButtonLink>
)

MailButtonLink.propTypes = {
  ...ButtonLink.propTypes,
  mailto: PropTypes.string,
}

export default ButtonLink
