import React from "react"

import clsx from "clsx"
import PropTypes from "prop-types"

import Image from "../image"

const Avatar = (props) => {

  const name = props.person.frontmatter.firstName
    + " " + props.person.frontmatter.lastName
  const shortName = props.person.frontmatter.firstName
  const avatar = props.person.frontmatter.avatar

  const handleKeyPress = event => {
    props.onClick && event.key === "Enter" && props.onClick()
  }

  return (
    <div
      role={"button"}
      tabIndex={props.onClick ? 0 : -1}
      className={clsx("avatar",
        props.onClick && "clickable"
      )}
      title={name}
      onClick={props.onClick}
      onKeyPress={handleKeyPress}
    >
      <Image src={avatar} tabIndex={-1} />
      <span>{ shortName }</span>
    </div>
  )
}

Avatar.propTypes = {
  className: (
    PropTypes.string
  ),
  person: (
    PropTypes.object
  ),
  onClick: (
    PropTypes.func
  ),
}

export default Avatar
