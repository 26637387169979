import React, {Component} from "react"

import clsx from "clsx";
import Slider from "react-slick"
import {v4 as uuidv4} from "uuid"

class Carousel extends Component {

  constructor(props) {
    super(props)
    this.id = `carousel-${uuidv4().substr(0, 8)}`
    this.state = {index: 0}
  }

  componentDidMount() {

    const domCarousel = document.getElementById(this.id)

    if (domCarousel) {

      const domArrows = domCarousel.getElementsByClassName("slick-arrow")
      const domDotsSlider = domCarousel.getElementsByClassName("dots-slider")

      domCarousel.addEventListener("keydown",
        event => event.key === "ArrowLeft"
          ? this.slider.slickPrev()
          : event.key === "ArrowRight"
            ? this.slider.slickNext()
            : undefined
      )

      if (this.props.autoplay) {
        console.log(this.id + ": autoplay ist konfiguriert.")
        if (this.props.pauseOnHover) {
          console.log(this.id + ": pauseOnHover ist konfiguriert.")
          Array.from(domArrows).forEach((arr) => {
            arr.addEventListener("mouseenter", () => this.slider.slickPause())
            arr.addEventListener("mouseleave", () => this.slider.slickPlay())
          })
          Array.from(domDotsSlider).forEach((arr) => {
            arr.addEventListener("mouseenter", () => this.slider.slickPause())
            arr.addEventListener("mouseleave", () => this.slider.slickPlay())
          })
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.autoplay) {
      this.slider.slickPlay()
    }
  }

  render() {
    return (
      <div
        id={this.id}
        className={clsx("carousel-wrapper", this.props.className,
          !(this.props.dots) && "no-dots"
        )}
        role={"button"}
        tabIndex={0}
      >
        <Slider
          ref={ref => {
            this.slider = ref
            this.props.carouselReference && this.props.carouselReference(this.slider)
          }}
          {...this.props}
          afterChange={idx => this.setState({index: idx})}
        />
        {this.props.dotsSlider && (
          <input
            type={"range"}
            className="dots-slider"
            min={0}
            max={React.Children.count(this.props.children) - 1}
            value={this.state.index}
            onChange={event => {
              this.slider.slickGoTo(event.currentTarget.value, false)
              this.setState({index: event.currentTarget.value})
            }}
          />
        )}
      </div>
    )
  }
}

Carousel.defaultProps = {
  lazyLoad: false,
  arrows: true,
  autoplaySpeed: 8000,
  dots: true,
  dotsSlider: false,
  pauseOnHover: true,
  pauseOnDotsHover: true,
  quoteBox: false,
  fixedArrows: false
}

export default Carousel